<template>
    <div class="stu-live" v-loading="connectLoading" element-loading-text="连接中">
        <div class="left-info">
            <div class="name-avatar">
                <!-- <span class="stu-avatar"> -->
                    <img class="stu-avatar" :src="liveRoomInfo.avatar || require('../../../assets/image/blank_headPic.png')">
                <!-- </span> -->
                <div class="nickname-fans">
                    <span class="stu-nickname">{{ liveRoomInfo.nickname }}</span>
                    <span class="fans-number">{{ latestData.fans }}粉丝</span>
                </div>
                <div class="follow" v-if="followFlag == false" @click="followOrCancel">
                    <span class="follow-word" >关注</span>
                </div>
                <div class="follow"  @click="followOrCancel" v-if="followFlag == true" style="background: linear-gradient(85deg, #FFA200, #F78B43);" >
                    <span class="follow-icon"></span>
                </div>
            </div>
            <div class="stu-label">
                <div class="stu-label-item" v-for="(item, key) in stuLabel" :key="key">
                    <span class="label-icon"></span>
                    <span class="label-word">{{ item }}</span>
                </div>
            </div>
            <div class="stu-synopsis">{{ liveRoomInfo.description }}</div>
            <div class="stu-likes-number">
                <div class="likes-icon-item" v-if="likesFlag == false" @click="giveOrCancelLikes"></div>
                <div class="likes-icon" v-if="likesFlag == true" @click="giveOrCancelLikes"></div>
                <div class="likes-number">
                    <div class="likes-numb-word">点赞数</div>
                    <div><span class="likes-n">{{ likesNumber }}</span><span class="likes-w">w</span></div>
                </div>
            </div>
        </div>

        <div class="mid-screen">
            <div class="live-video" id="live-video"></div>
            <div class="top-box">
                <div class="left-bag-coupon">
                    <div class="item bag-box" @click="receiveBag" v-if="luckyBagHandle.status == 1 && luckyBagHandle.countdown > 0">
                        <div class="item-container">
                            <img class="icon" src="~assets/image/othersLive/luck-bag.png" alt="福袋" title="福袋" />
                        </div>
                        <div class="countdown">{{ luckyBagHandle.countdown | bagCountdown }}</div>
                    </div>
                    <div class="item coupon-box" v-if="couponHandle.status == 1 || couponHandle.status == 2">
                        <div class="item-container">
                            <img class="icon" src="~assets/image/othersLive/coupon.png" alt="优惠券" title="优惠券" />
                        </div>
                        <div class="corner-mark">{{ couponHandle.coupon_num }}</div>
                    </div>
                </div>
                <div class="right-goods-info" v-if="chooseGoodsHandle.goods_info">
                    <div class="left-paster">
                        <img
                            class="paster-img"
                            :src="chooseGoodsHandle.goods_info.goods_master_img"
                        />
                    </div>
                    <div class="right-info">
                        <div class="title" v-html="chooseGoodsHandle.goods_info.goods_name"></div>
                        <div class="price-box">
                            <div class="old-price">
                                <span class="label">日常价: </span>
                                <span class="price">￥{{ chooseGoodsHandle.goods_info.goods_market_price }}</span>
                            </div>
                            <div class="new-price">
                                <span class="label">宠粉价: </span>
                                <span class="price">￥{{ chooseGoodsHandle.goods_info.goods_discount_price }}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="bottom-box">
                <div class="lift-bullet-action">
                    <div class="bullet-chat" v-if="bulletChatFlag == false">
                        <el-scrollbar style="height: 100%">
                            <div class="bullet-chat-list">
                                <div class="item" v-for="(item, index) in barrageHandle" :key="index">
                                    <span class="username">{{ item.nickname }}：</span>
                                    <span class="content">{{ item.barrage }}</span>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                    <div class="bullet-chat" v-if="bulletChatFlag == true"></div>
                    <div class="action-input">
                        <el-input class="input" v-model="actionValue" placeholder="说点什么..." @keydown.enter.native="keyDown" ref="input"></el-input>
                        <span class="action-icon"></span>
                    </div>
                </div>
                <div class="right-goods-popup" v-if="chooseGoodsHandle.goods_info">
                    <div class="img-box">
                        <div class="explain">讲解中</div>
                        <img
                            class="goods-img"
                            :src="chooseGoodsHandle.goods_info.goods_master_img"
                        />
                    </div>
                    <div class="goods-info">
                        <div class="title">{{ chooseGoodsHandle.goods_info.goods_name }}</div>
                        <div class="coupon" @click="grabCoupons" v-if="couponHandle.coupon_type == 1 && couponHandle.status == 1 || couponHandle.status == 2">
                            <span class="coupon-item">满</span>
                            <span class="coupon-item-numb">{{ parseInt(couponHandle.coupon_full) }}</span>
                            <span class="coupon-item">减</span>
                            <span class="coupon-item-numb">{{ parseInt(couponHandle.coupon_minus) }}</span>
                        </div>
                        <div class="coupon" @click="grabCoupons" v-if="couponHandle.coupon_type == 2 && couponHandle.status == 1 || couponHandle.status == 2">
                            <span class="coupon-item">满</span>
                            <span class="coupon-item-numb">{{ parseInt(couponHandle.coupon_full) }}</span>
                            <span class="coupon-item">打</span>
                            <span class="coupon-item-numb">{{ parseInt(couponHandle.coupon_minus) }}</span>
                            <span class="coupon-item">折</span>
                        </div>
                    </div>
                </div>
            </div>

            <el-dialog width="400px" top="15%" :visible.sync="luckyBagDialog" class="luckyBagDialog" :modal-append-to-body="false">
                <div class="avatar">
                    <img class="stu-avatar" :src="liveRoomInfo.avatar || require('../../../assets/image/blank_headPic.png')">
                </div>
                <div class="condition">关注我才可以领福袋哦</div>
                <div class="icon"></div>
                <div class="bottom-box">
                    <div class="left-btn" @click="cancelBagDialog">我再想想</div>
                    <div class="right-btn" @click="followOrCancel">立即关注</div>
                </div>
            </el-dialog>

            <el-dialog width="400px" top="15%" :visible.sync="followBagDialog" class="luckyBagDialog" :modal-append-to-body="false">
                <div class="title">{{ luckyBagHandle.bag_name }}</div>
                <div class="mid-box">
                    <div class="count-down">
                        <div class="times">
                            <div class="times-numb">{{ luckyBagHandle.countdown | bagCountdown }}</div>
                            <div class="times-word">倒计时</div>
                        </div>
                        <div class="people-numb">可中奖数10人 | {{ luckyBagHandle.number }}人已参与</div>
                    </div>
                    <div class="participate">
                        <div class="part-condition">参与条件</div>
                        <div class="part-word">参与口令：{{ luckyBagHandle.command_title }}</div>
                    </div>
                </div>
                <div class="send" @click="sendWord">去发送口令</div>
            </el-dialog>

            <el-dialog width="360px" top="11%" :visible.sync="couponDialog" class="couponDialog" :modal-append-to-body="false">
                <div class="avatar">
                    <img class="stu-avatar" :src="liveRoomInfo.avatar || require('../../../assets/image/blank_headPic.png')">
                </div>
                <div class="coupon-tips">恭喜你，领券成功</div>
                <div class="coupon-details">
                    <div class="discount" v-if="couponHandle.coupon_type == 1">
                        <span class="discount-word">满</span>
                        <span class="discount-numb">{{ parseInt(couponHandle.coupon_full) }}</span>
                        <span class="discount-word">减</span>
                        <span class="discount-numb">{{ parseInt(couponHandle.coupon_minus) }}</span>
                    </div>
                    <div class="discount" v-if="couponHandle.coupon_type == 2">
                        <span class="discount-numb">{{ parseInt(couponHandle.coupon_minus) }}</span>
                        <span class="discount-word">折</span>
                    </div>
                    <div class="category-name">
                        <div class="name"><span class="name-item">商品券</span></div>
                        <div class="category">{{ couponHandle.coupon_name }}</div>
                    </div>
                    <div class="use-limited">
                        <div class="use-details">仅限指定商品组合使用</div>
                        <div class="limited">每人限领1张</div>
                    </div>
                    <div class="use-now"><span @click="useCoupon">立即用券</span></div>
                </div>
            </el-dialog>
        </div>

        <div class="right-operation">
            <div class="top-box">
                <el-scrollbar style="height: 100%">
                    <div class="title-box">
                        <img class="decorate" src="~assets/image/student/live/scriptRightDecorate.png" />
                        <div class="title">在线人数TOP10</div>
                    </div>
                    <div class="list">
                        <div class="container">
                            <div class="rank-item" v-for="(item, index) in audienceSortList" :key="index">
                                <div class="rank rank-one" v-if="index +1 == 1"></div>
                                <div class="rank rank-two" v-else-if="index +1 == 2"></div>
                                <div class="rank rank-three" v-else-if="index +1 == 3"></div>
                                <div class="rank rank-word" v-else>{{ index +1 }}</div>
                                <div class="item">
                                    <div class="avatar-box">
                                        <img class="avatar-img" :src="item.avatar" alt="user" title="user" />
                                    </div>
                                    <div class="desc">
                                        <span class="username">{{ item.nickname }}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="mid-box">
                <el-scrollbar style="height: 100%">
                    <div class="title-box">
                        <img class="decorate" src="~assets/image/student/live/scriptRightDecorate.png" />
                        <div class="title">历史弹幕</div>
                    </div>
                    <div class="list">
                        <div class="container">
                            <div class="item" v-for="(item, key) in historicalBarrage" :key="key">
                                <div class="avatar-box">
                                    <img class="avatar-img" :src="item.avatar" alt="user" title="user" />
                                </div>
                                <div class="desc">
                                    <span class="username">{{ item.nickname }}：</span>
                                    <span>{{ item.barrage }}</span>
                                    <!-- <span v-else>进入直播间进入直播间进入直播间进入直播间进入直播间进入直播间进入直播间进入直播间</span> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </el-scrollbar>
            </div>
            <div class="bottom-box">
                <div class="barrage-switch" @click="switchBarrage">
                    <div class="open" v-if="bulletChatFlag == false"></div>
                    <div class="shut" v-if="bulletChatFlag == true"></div>
                </div>
                <div class="volume-adjusting">
                    <div class="volume-icon"></div>
                    <c-progress class="c-progress" 
                    :percent="percent" @percentChange="onPercentChange" :show-per-text="false" :width="150"
                    :sliderWidth="16" :progressColor="'#4B45FF'"/>
                </div>
                <div class="enlarge"></div>
            </div>
        </div>
        
    </div>
</template>

<script>
import { getBagCommand } from '@/utils/apis.js';
import CProgress from './Progress'
export default {
    data() {
        return {
            wsServer: 'wss://onlive.e-class.me:2345',
            ws: null,
            player: null,
            playerURL: this.$route.query.play_url,
            stuLabel: ['减肥', '气质', '气质'],
            actionValue: '',
            percent: 30,
            likesFlag: false,
            followFlag: false,
            bulletChatFlag: false,
            couponDialog: false,
            luckyBagDialog: false,
            followBagDialog: false,
            connectLoading: true,
            bagCountdown: 0,
            nowGoodsID: 0,
            bagTimer: null,
            playTimer: null,
            commonParams: {
                room_id: this.$route.query.t_Room_id,
                train_id: this.$route.query.t_Train_id,
                script_id: this.$route.query.script_id,
                user_id: this.$route.query.tid,
                advert_id: this.$route.query.advert_id,
                nickname: localStorage.getItem('nickname'),
                avatar: localStorage.getItem('teacherAvatar'), 
                send_msg_user_id: localStorage.getItem('teacherId'),
            },
            liveRoomInfo: {},
            historicalBarrage: [],
            sendBarrage: [],
            audienceSortList: [],
            luckyBagData: {},
            couponData: [],
            chooseGoodsData: [],
            BagCommandData: [],
            latestData: {},
            luckyBag: {},
        }
    },
    mounted() {
        this.init();
        // console.log(this.$route.query.play_url);
    },
    components: {
        CProgress
    },
    computed: {
        likesNumber() {
            let likes = this.latestData.like / 10000;
            return likes.toFixed(3);
        },
        barrageHandle() {
            return this.historicalBarrage.concat(this.sendBarrage);
        },
        luckyBagHandle() {
            let luckyBagObj = {}
            if (this.luckyBagData != undefined) {
                for (let x = 0; x < this.luckyBagData.length; x ++) {
                    luckyBagObj = this.luckyBagData[x];
                }
            };
            
            for (let i = 0; i < this.BagCommandData.length; i ++) {
                if (luckyBagObj.command_id == this.BagCommandData[i].id) {
                    luckyBagObj.command_title = this.BagCommandData[i].title;
                }
            };
            if (luckyBagObj.countdown > 0) {
                this.bagTimer = setTimeout(() => {
                    luckyBagObj.countdown --;
                }, 1000)
            };
            return luckyBagObj
            
        },
        couponHandle() {
            let couponList = [];
            let couponObj = {};
            if (this.couponData != undefined) {
                for (let x = 0; x < this.couponData.length; x ++) {
                    couponObj.coupon_name = this.couponData[x].coupon_name;
                    couponObj.coupon_type = this.couponData[x].coupon_type;
                    couponObj.coupon_num = this.couponData[x].child.length
                    couponList = this.couponData[x].child;
                }
            }
            
            for (let i = 0; i < couponList.length; i ++) {
                if (this.nowGoodsID == couponList[i].goods_id) {
                    couponObj.goods_id = couponList[i].goods_id;
                    couponObj.coupon_student_id = couponList[i].coupon_student_id
                    couponObj.status = couponList[i].status
                    couponObj.coupon_full = couponList[i].coupon_full
                    couponObj.coupon_minus = couponList[i].coupon_minus
                }
            }
            return couponObj
        },
        chooseGoodsHandle() {
            let nowGoodsInfo = {}
            if (this.chooseGoodsData != undefined) {
                for (let s = 0; s < this.chooseGoodsData.length; s ++) {
                    if (this.nowGoodsID == this.chooseGoodsData[s].goods_id) {
                        nowGoodsInfo = this.chooseGoodsData[s];
                    }
                }
            }
            return nowGoodsInfo
        },
        wsActions() {
            const {room_id, train_id, script_id, user_id, nickname, avatar, send_msg_user_id} = this.commonParams;
            const obj = {
                bind: { type: 'bind', room_id: room_id, user_id: user_id},
                historicalBarrage: { type: 'get_all_barrage', train_id: train_id, user_id: user_id},
                sendBarrag: {
                    type: 'send_barrage',
                    room_id: room_id,
                    msg: [{nickname: nickname, avatar: avatar, send_msg_user_id: send_msg_user_id, barrage: ''}]
                },
                audienceSort: { type: 'get_audience_sort', train_id: train_id, room_id: room_id},
                stuOperation: { type: 'get_student_data', user_id: user_id, train_id: train_id, script_id: script_id},
                interpretation: { type: 'get_interpretation_goods', room_id: room_id},
                stuNowShelvesGoods: { type: 'get_student_now_shelves_goods', user_id: user_id, train_id: train_id, script_id: script_id},
                newestData: {type: 'data', room_id: 16},
            };
            return obj
        }
    },
    filters: {
        bagCountdown(time) {
            const value = time * 1;
            const times = [];
            const getValue = (value) => ((value + "").length <= 1 ? `0${value}` : value);
            let minute = getValue(Math.floor(value / 60) % 60);
            let second = getValue(value % 60);
            return [minute, second].join(":");
        },
    },
    methods: {
        init() {
            this.ws = new WebSocket(this.wsServer);
            this.ws.onopen = this.webSocketOpen;
            this.ws.onmessage = this.webSocketOnmessage;
            this.ws.onclose = this.webSocketClose;
            this.ws.onerror = this.webSocketError;
            this.getLuckyBagCommand();
        },
        webSocketOpen() {
            this.connectLoading = false;
            // console.log('连接成功', this.ws.readyState);
            //绑定直播间
            this.ws.send(JSON.stringify(this.wsActions.bind));
            //历史弹幕
            this.ws.send(JSON.stringify(this.wsActions.historicalBarrage));
            //在线人数top
            this.ws.send(JSON.stringify(this.wsActions.audienceSort));
            //优惠券、福袋、商品
            this.ws.send(JSON.stringify(this.wsActions.stuOperation))
            //发送弹幕
            this.keyDown();
            //直播间最新数据
            this.ws.send(JSON.stringify(this.wsActions.newestData));
            //正在讲解的商品
            this.ws.send(JSON.stringify(this.wsActions.interpretation))
            //当前上架商品
            this.ws.send(JSON.stringify(this.wsActions.stuNowShelvesGoods))
        },
        webSocketOnmessage(evt) {
            let roomInfo = JSON.parse(evt.data);
            //绑定直播间数据
            if (roomInfo.data.web_type == 'bind') {
                if(roomInfo.data['0'].status == 1) {
                    this.initPlayer();
                } else {
                    this.$message.warning('当前暂无直播！')
                };
                this.liveRoomInfo = roomInfo.data['0'];
            };
            //历史弹幕数据
            if (roomInfo.data.web_type == 'get_all_barrage') this.historicalBarrage = roomInfo.data.list
            //发送弹幕数据
            if (roomInfo.data.web_type == 'send_barrage') this.sendBarrage = roomInfo.data['0'];
            //在线人数top数据
            if (roomInfo.data.web_type == 'get_audience_sort') this.audienceSortList = roomInfo.data.list;
            //直播间最新数据
            if (roomInfo.data.web_type == 'data') this.latestData = roomInfo.data['0'];
            //优惠券、福袋、商品
            if (roomInfo.data.web_type == 'get_student_data') {
                this.luckyBagData = roomInfo.data.student_bag_list;
                this.couponData = roomInfo.data.student_coupon_list;
                this.chooseGoodsData = roomInfo.data.choose_goods_data;
            }
            //福袋优惠券领取
            if (roomInfo.data.web_type == 'receive_op') {
                if (roomInfo.code == 1) {
                    this.$message.success(roomInfo.msg)
                } else {
                    this.$message.warning(roomInfo.msg)
                }
            };
            //当前上架商品
            if (roomInfo.data.web_type == 'get_student_now_shelves_goods') this.nowGoodsID = roomInfo.data.goods_id;
            
        },
        webSocketClose(e) {
            console.log("链接中断", e);
            if (e.code != 1000) {
                this.connectLoading = true;
            }
        },
        webSocketError(err) {
            console.log("链接错误", err);
        },
        //发送弹幕
        keyDown() {
            if (this.actionValue != '') {
                let receiveBag = {type: 'receive_op', 
                    id: this.luckyBagHandle.id, 
                    genre: 2, 
                    receive_user_id: this.commonParams.send_msg_user_id, 
                    train_id: this.commonParams.train_id,
                    command_string: this.actionValue
                };
                this.wsActions.sendBarrag.msg[0].barrage = this.actionValue;
                this.ws.send(JSON.stringify(this.wsActions.sendBarrag));
                if (this.actionValue == this.luckyBagHandle.command_title) {
                    this.ws.send(JSON.stringify(receiveBag))
                }
                this.actionValue = '';
            }
        },
        //直播画面
        initPlayer() {
            if (this.player) {
				this.player.destroy();
				this.player = null;
			};
            let playerOptions = {
                webrtc: this.playerURL,
                // webrtc: "webrtc://playlive.e-class.me/live/5c4765a0855b099586e5afa1223be3ec?txSecret=9d666c1f2e9e0e102ffe8e73f7d34786&txTime=6298DFAC",
                autoplay : true,     //视频自动播放
                controls: "none",  //不显示控件
                x5_orientation: 1, //竖屏
                live: true, //直播类型
                listener: (msg) => {
                    // console.log(msg);
                    // 播放失败，重新连接
					if (msg.type == "error") {
						this.playTimer = setTimeout(() => {
							this.player.load();
						}, 3000);
					} else if (msg.type == 'play') {
                        clearTimeout(this.playTimer)
                    };
                },
            }
            this.player = new TcPlayer('live-video', playerOptions);
        },
        //点赞取消点赞 
        giveOrCancelLikes() {
            if (this.likesFlag == false) {
                this.likesFlag = true;
            } else if (this.likesFlag == true) {
                this.likesFlag = false;
            }
        },
        //关注取消关注
        followOrCancel() {
            if (this.followFlag == false) {
                this.followFlag = true;
                this.cancelBagDialog()
            } else if (this.followFlag == true) {
                this.followFlag = false;
            }
        },
        //领取优惠券
        grabCoupons() {
            let receiveCoupon = {type: 'receive_op', 
                id: this.couponHandle.coupon_student_id, 
                genre: 1, 
                receive_user_id: this.commonParams.send_msg_user_id, 
                train_id: this.commonParams.train_id,
            };
            this.ws.send(JSON.stringify(receiveCoupon))
            this.couponDialog = true;
        },
        //立即用券
        useCoupon() {
            this.couponDialog = false;
        },
        //领取福袋Dialog
        receiveBag() {
            if (this.luckyBagHandle.in_type == 1) {
                this.followBagDialog = true
            } else if (this.luckyBagHandle.in_type == 2) {
                this.followBagDialog = true;
            } else if (this.followFlag == false) {
                this.luckyBagDialog = true
            } else if (this.followFlag == true) {
                this.followBagDialog = true;
            }
            
        },
        cancelBagDialog() {
            this.followBagDialog = false
        },
        //发送口令
        sendWord() {
           this.cancelBagDialog();
            this.$nextTick(() => {
                this.$refs.input.focus();
            });
        },
        //开关弹幕
        switchBarrage() {
            if (this.bulletChatFlag == true) {
                this.bulletChatFlag = false;
            } else if (this.bulletChatFlag == false) {
                this.bulletChatFlag = true;
            }
        },
        //福袋口令列表
        async getLuckyBagCommand() {
            let res = await getBagCommand()
            this.BagCommandData = res.data
        },
        //进度条拖拽
        onPercentChange(per) {
            // console.log(this.player);
            this.player.volume(per);
        },
    }
}
</script>

<style scoped lang="scss">
    .stu-live {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        background: #070932;
    }
    .left-info {
        display: flex;
        flex-direction: column;
        width: 38%;
        margin: 34px 37px 0 28px;

        .name-avatar {
            display: flex;
            flex-direction: row;
            width: 247px;
            height: 48px;
            background: #262963;
            border-radius: 24px;

            .stu-avatar {
                display: inline-block;
                width: 46px;
                height: 46px;
                border-radius: 50%;
                // border: 1px solid #DADCE7;
            }

            .nickname-fans {
                display: flex;
                flex-direction: column;
                width: 105px;
                height: 32px;
                margin: 5px 0 8px 10px;
                // border: 1px solid #DADCE7;
            }
            .stu-nickname {
                display: inline-block;
                height: 16px;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
            }
            .fans-number {
                display: inline-block;
                height: 12px;
                margin-top: 8px;
                font-size: 12px;
                font-weight: 400;
                color: #FFFFFF;
            }
            .follow {
                width: 53px;
                height: 40px;
                margin: 4px 0 4px 36px;
                text-align: center;
                background: linear-gradient(85deg, #FB2D69, #FF3E6C, #FC2A7D);
                border-radius: 20px;
                cursor: pointer;
            }
            .follow-word {
                display: inline-block;
                height: 16px;
                margin-top: 8px;
                font-size: 16px;
                font-weight: bold;
                color: #FFFFFF;
            }
            .follow-icon {
                display: inline-block;
                width: 21px;
                height: 21px;
                margin-top: 8px;
                background: url('../../../assets/image/othersLive/follow.png');
            }
        }

        .stu-label {
            display: flex;
            flex-direction: row;
            // width: 247px;
            height: 30px;
            margin-top: 20px;
            .stu-label-item {
                display: flex;
                flex-direction: row;
                width: 76px;
                height: 28px;
                line-height: 30px;
                margin-right: 5px;
                background: #262963;
                border-radius: 14px;
            }
            .label-icon {
                display: inline-block;
                width: 16px;
                height: 15px;
                margin: 7px 5px 6px 5px;
                background: url('../../../assets/image/othersLive/label.png');
            }
            .label-word {
                display: inline-block;
                width: 38px;
                font-size: 16px;
                color: #FFFFFF;
            }
        }

        .stu-synopsis {
            margin: 21px 0 21px 0;
            font-size: 16px;
            color: #fff;
        }
        
        .stu-likes-number {
            display: flex;
            flex-direction: row;
            height: 53px;
            .likes-icon {
                width: 53px;
                height: 53px;
                border-radius: 50%;
                background: url('../../../assets/image/othersLive/dianzan.png');
                cursor: pointer;
            }
            .likes-icon-item {
                width: 53px;
                height: 53px;
                border-radius: 50%;
                background: url('../../../assets/image/othersLive/dianzan-fu.png');
                cursor: pointer;
            }
            .likes-number {
                margin-left: 20px;
            }
            .likes-numb-word {
                margin-bottom: 6px;
                font-size: 16px;
                color: #fff;
            }
            .likes-n {
                font-size: 24px;
                font-weight: bold;
                color: #4B45FF
            }
            .likes-w {
                font-size: 16px;
                color: #fff;
            }
        }
    }

    .mid-screen {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        position: relative;
        width: 29%;
        margin: 20px 21px 19px 0;
        background: url('../../../assets/image/student/live_background.png');
        background-size: 100% 100%;
        border-radius: 20px;
        .top-box {
            display: flex;
            .left-bag-coupon {
                display: flex;
                justify-content: space-between;
                width: 125px;
                margin: 12px 0 0 10px;
                .item {
                    position: relative;
                    width: 60px;
                    height: 60px;
                    border-radius: 14px;
                    background-color: rgba(0, 0, 0, 0.23);
                    cursor: pointer;
                    .icon {
                        width: 100%;
                        height: 100%;
                        // transform: rotate(35deg) translate(0, 9px);
                    }
                    .item-container {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                    }
                    .countdown {
                        position: absolute;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        bottom: 0;
                        left: 50%;
                        transform: translateX(-50%);
                        width: 50px;
                        height: 20px;
                        font-size: 12px;
                        color: #fff;
                        border-radius: 10px;
                        background-color: rgba(0, 0, 0, 0.5);
                        opacity: 0.9;
                    }
                    .corner-mark {
                        position: absolute;
                        top: 0;
                        right: 0;
                        transform: translate(40%, -40%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 20px;
                        height: 20px;
                        font-size: 14px;
                        color: #d50715;
                        background: #fece0a;
                        box-shadow: 0px 4px 7px 0px rgba(94, 57, 1, 0.3);
                        border-radius: 50%;
                    }
                }
            }
            .right-goods-info {
                flex: 1;
                box-sizing: border-box;
                display: flex;
                justify-content: space-between;
                height: 135px;
                margin: 19px 0 0 40px;
                .left-paster {
                    flex: 1;
                    margin-right: 15px;
                    .paster-img {
                        height: 135px;
                        width: 100%;
                    }
                }
                .right-info {
                    flex: 1;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    .price-box {
                        font-size: 16px;
                        color: #000;
                        .old-price {
                            margin-bottom: 5px;
                            .price {
                                text-decoration: line-through;
                            }
                        }
                        .new-price {
                            // font-weight: 700;
                            .price {
                                font-size: 16px;
                                color: #b41222;
                                white-space: nowrap;
                            }
                        }
                    }
                }
            }
        }
        .bottom-box {
            display: flex;
            justify-content: space-between;
            margin: 0 10px 21px 10px;
            overflow: hidden;
            .lift-bullet-action {
                display: flex;
                flex-direction: column;
                .bullet-chat {
                    height: 270px;
                    width: 100%;
                    overflow: hidden;
                    margin-bottom: 15px;
                    .bullet-chat-list {
                        .item {
                            width: fit-content;
                            margin-bottom: 10px;
                            padding: 5px 10px;
                            word-break: break-all;
                            font-size: 16px;
                            color: #ff3e6c;
                            border-radius: 15px;
                            background-color: rgba(0, 0, 0, 0.3);
                            .content {
                                margin-left: 9px;
                                color: #fff;
                            }
                        }
                        .item:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
            .action-input {
                position: relative;
                width: 254px;
            }
            ::v-deep .input {
                height: 48px;
                input {
                    height: 100%;
                    padding: 0 12px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.4);
                    border: none;
                    border-radius: 23px;
                }
            }
            .action-icon {
                position: absolute;
                top: 8px;
                right: 10px;
                display: inline-block;
                width: 22px;
                height: 31px;
                background: url('../../../assets/image/othersLive/yuyin.png');
                cursor: pointer;
            }
            .right-goods-popup {
                position: absolute;
                right: 10px;
                bottom: 23px;
                width: 176px;
                // height: 267px;
                box-sizing: border-box;
                align-self: flex-end;
                background-color: #fff;
                border-radius: 6px;
                .img-box {
                    position: relative;
                    width: 170px;
                    height: 170px;
                    margin: 2px 2px 0 2px;
                    overflow: hidden;
                    .explain {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 68px;
                        height: 20px;
                        font-size: 14px;
                        color: #FFFFFF;
                        text-align: right;
                        background: url('../../../assets/image/othersLive/explain.png');
                    }
                    .goods-img {
                        width: 100%;
                        height: 100%;
                        vertical-align: middle;
                        border-radius: 6px 6px 0 0;
                    }
                }
                .goods-info {
                    margin: 7px 5px;
                    overflow: hidden;
                    .title {
                        box-sizing: border-box;
                        height: 31px;
                        font-size: 14px;
                        line-height: 18px;
                        color: #222;
                        overflow: hidden;
                        display: -webkit-box;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2; /*几行就写数字几*/
                        text-overflow: ellipsis;
                    }
                    .coupon {
                        height: 42px;
                        margin-top: 7px;
                        line-height: 42px;
                        color: #fff;
                        background: url('../../../assets/image/othersLive/rob.png');
                        cursor: pointer;
                        .coupon-item {
                            font-size: 12px;
                        }
                        .coupon-item-numb {
                            font-size: 18px;
                        }
                    }
                    .coupon span {
                        display: inline-block;
                    }
                    .coupon span:first-child {
                        margin-left: 16px;
                    }
                }
            }
        }
        .live-video {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            ::v-deep video {
                width: 100%;
                height: 100%;
                object-fit: cover !important;
            }
        }
    }

    .right-operation {
        display: flex;
        flex-direction: column;
        width: 29%;
        margin: 20px 20px 0 0;
        .top-box {
            display: flex;
            flex-direction: column;
            height: 369px;
            margin-bottom: 21px;
            background: #0C0E3F;
            border: 1px solid rgba(67, 154, 255, 0.15);
            box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
            border-radius: 20px;
            .title-box {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                margin-top: 27px    ;
                margin-bottom: 32px;
                padding-left: 20px;
                font-size: 18px;
                .decorate {
                    width: 31px;
                    height: 11px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .list {
                box-sizing: border-box;
                display: flex;
                flex-direction: row;
                align-items: center;
                width: 100%;
                .container {
                    box-sizing: border-box;
                    width: 75%;
                    overflow: hidden;
                }
                .rank-item {
                    display: flex;
                    flex-direction: row;
                    margin: 0 0 15px 32px;
                }
                .rank {
                    width: 27px;
                    height: 29px;
                    line-height: 29px;
                    font-size: 16px;
                    font-weight: bold;
                    color: #FFFFFF;
                    margin-right: 9px;
                }
                .rank-one {
                    background: url('../../../assets/image/othersLive/one.png') no-repeat;
                }
                .rank-two {
                    background: url('../../../assets/image/othersLive/two.png') no-repeat;
                }
                .rank-three {
                    background: url('../../../assets/image/othersLive/three.png') no-repeat;
                }
                .rank-word {
                    width: 20px;
                    height: 29px;
                    margin-left: 8px;
                }
                .item {
                    box-sizing: border-box;
                    display: flex;
                    width: fit-content;
                    height: 30px;
                    padding-right: 30px;
                    font-size: 16px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 15px;
                    .avatar-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        margin-right: 9px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #fff;
                        vertical-align: middle;
                        .avatar-img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .desc {
                        flex: 1;
                        overflow: hidden;
                        line-height: 30px;
                        .username {
                            color: #4b45ff;
                        }
                    }
                }
            }
        }
        .mid-box {
            display: flex;
            flex-direction: column;
            overflow: hidden;
            height: 545px;
            margin-bottom: 47px;
            background: #0C0E3F;
            border: 1px solid rgba(67, 154, 255, 0.15);
            box-shadow: 0px 0px 40px 0px rgba(12, 21, 48, 0.3);
            border-radius: 20px;
            .title-box {
                box-sizing: border-box;
                display: flex;
                align-items: center;
                margin-top: 27px    ;
                margin-bottom: 32px;
                padding-left: 20px;
                font-size: 18px;
                .decorate {
                    width: 31px;
                    height: 11px;
                    margin-right: 10px;
                    vertical-align: middle;
                }
                .title {
                    font-size: 18px;
                    font-weight: bold;
                    color: #FFFFFF;
                }
            }
            .list {
                box-sizing: border-box;
                display: flex;
                flex-direction: column;
                align-items: center;
                width: 100%;
                .container {
                    box-sizing: border-box;
                    width: 75%;
                    overflow: hidden;
                }
                .item {
                    box-sizing: border-box;
                    display: flex;
                    width: fit-content;
                    padding-right: 10px;
                    height: 30px;
                    margin-bottom: 15px;
                    font-size: 16px;
                    color: #fff;
                    background-color: rgba(0, 0, 0, 0.3);
                    border-radius: 15px;
                    .avatar-box {
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        width: 24px;
                        height: 24px;
                        margin-right: 9px;
                        border-radius: 50%;
                        overflow: hidden;
                        background-color: #fff;
                        vertical-align: middle;
                        .avatar-img {
                            width: 24px;
                            height: 24px;
                        }
                    }
                    .desc {
                        flex: 1;
                        overflow: hidden;
                        line-height: 30px;
                        .username {
                            color: #4b45ff;
                        }
                    }
                }
            }
        }
        .bottom-box {
            display: flex;
            justify-content: space-between;
            height: 76px;
            .barrage-switch {
                width: 93px;
                height: 100%;
                cursor: pointer;
                .open {
                    height: 36px;
                    margin-bottom: 4px;
                    background: url('../../../assets/image/othersLive/bullet-open.png');
                }
                .shut {
                    height: 36px;
                    background: url('../../../assets/image/othersLive/bullet-shut.png');
                }
            }
            .volume-adjusting {
                display: flex;
                flex-direction: row;
                margin-top: 10px;
                .volume-icon {
                    width: 22px;
                    height: 16px;
                    margin-right: 5px;
                    background: url('../../../assets/image/othersLive/shengyin.png');
                }
                .volume-progress {
                    position: relative;
                    width: 150px;
                    height: 16px;
                    .progress {
                        margin-bottom: 6px;
                        height: 1px;
                        background: #262963;
                        border: 2px solid #262963;
                        border-radius: 2px;
                    }
                    .progress::-webkit-progress-value {
                        position: absolute;
                        top: 6px;
                        height: 1px;
                        background: #4B45FF;
                        border: 2px solid #4B45FF;
                        border-radius: 2px;
                    }
                    .progress_btn {
                        position: absolute;
                        left: 0;
                        top: 0;
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                        cursor: pointer;
                        background: #EEEEEE;
                    }
                }
            }
            .enlarge {
                width: 18px;
                height: 17px;
                background: url('../../../assets/image/othersLive/lashen.png');
            }
        }
    }

    ::v-deep .el-scrollbar__wrap {
        overflow-x: hidden;
    }
    .luckyBagDialog {
        display: flex;
        flex-direction: column;
        .avatar {
            position: absolute;
            top: -60px;
            left: 35%;
            width: 100px;
            height: 100px;
            .stu-avatar {
                width: 100px;
                height: 100px;  
                border-radius: 50%;
            }
        }
        .condition {
            width: 180px;
            height: 18px;
            margin: 46px auto;
            font-size: 18px;
            font-weight: 500;
            color: #050213;
        }
        .icon {
            width: 140px;
            height: 149px;
            margin: 36px auto;
            background: url('../../../assets/image/othersLive/lucky-bag.png');
        }
        .bottom-box {
            display: flex;
            flex-direction: row;
            height: 60px;
            margin: 29px 0 0 0;
            text-align: center;
            line-height: 54px;
            border-top: 1px solid #DDDDDD;
            cursor: pointer;
            .left-btn {
                flex: 1;
                height: 54px;
                font-size: 16px;
                color: #060111;
                border-right: 1px solid #DDD;
            }
            .right-btn {
                flex: 1;
                font-size: 16px;
                color: #4B45FF;
            }
        }

        .title {
            // width: 36px;
            height: 18px;
            margin: 0 auto;
            text-align: center;
            font-size: 18px;
            font-weight: bold;
            color: #070932;
        }
        .mid-box {
            display: flex;
            flex-direction: column;
            width: 360px;
            height: 180px;
            margin: 60px 19px 0 21px;
            .count-down {
                display: flex;
                justify-content: space-between;
                height: 80px;
                background: #F8F8F8;
                border-radius: 4px;
                margin-bottom: 20px;
                .times {
                    width: 84px;
                    height: 41px;
                    margin: 21px 0 16px 10px;
                    border-right: 1px solid #DDD;
                    .times-numb {
                        width: 63px;
                        height: 19px;
                        margin-bottom: 9px;
                        font-size: 24px;
                        font-weight: bold;
                        color: #4B45FF;
                    }
                    .times-word {
                        width: 42px;
                        height: 14px;
                        font-size: 14px;
                        font-weight: 400;
                        color: #5C5C6F;
                    }
                }
                .people-numb {
                    width: 164px;
                    height: 17px;
                    margin: 51px 10px 12px 0;
                    font-size: 14px;
                    font-weight: 400;
                    color: #5C5C6F;
                }
            }
            .participate {
                display: flex;
                flex-direction: column;
                height: 80px;
                background: #F8F8F8;
                border-radius: 4px;
                .part-condition {
                    height: 18px;
                    margin: 18px 0 13px 10px;
                    font-size: 18px;
                    font-weight: 500;
                    color: #5C5C6F;
                }
                .part-word {
                    height: 14px;
                    margin: 0 0 17px 12px;
                    font-size: 14px;
                    font-weight: 400;
                    color: #5C5C6F;
                }
            }
        }
        .send {
            width: 360px;
            height: 40px;
            margin: 40px 20px 21px 20px;
            font-size: 14px;
            font-weight: 400;
            color: #FFFFFF;
            text-align: center;
            line-height: 40px;
            background: #FF3E6C;
            border-radius: 4px;
            cursor: pointer;
        }
    }
    ::v-deep .luckyBagDialog {
        .el-dialog {
            height: 380px;
            margin-left: 45%;
            border-radius: 20px;
        }
        .el-dialog__body {
            padding: 0;
        }
        .el-icon-close:before {
            content: ''
        }
    }
    .couponDialog {
        display: flex;
        flex-direction: column;
        .avatar {
            width: 60px;
            height: 60px;
            margin: -30px auto;
            border-radius: 50%;
            .stu-avatar {
                width: 60px;
                height: 60px;
                border-radius: 50%;
            }
        }
        .coupon-tips {
            width: 146px;
            height: 19px;
            margin: 40px auto;
            font-size: 18px;
            font-weight: 500;
            color: #FFFFFF;
        }
        .coupon-details {
            width: 316px;
            height: 260px;
            text-align: center;
            background: url('../../../assets/image/othersLive/coupon-bag-item.png');
            background-size: 100% 100%;
            .discount {
                padding-top: 15px;
                color: #FE3266;
                .discount-numb {
                    font-size: 48px;
                }
                .discount-word {
                    font-size: 18px;
                }
            }
            .category-name {
                display: flex;
                flex-direction: row;
                margin: 20px 0 20px 55px;
                .name {
                    // width: 53px;
                    height: 25px;
                    background: #F9D6DF;
                    border-radius: 4px;
                    .name-item {
                        width: 46px;
                        height: 14px;
                        font-size: 14px;
                        font-weight: bold;
                        color: #FE3266;
                        line-height: 25px;
                    }
                }
                .category {
                    // width: 152px;
                    height: 18px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #1B162A;
                }
            }
            .use-limited {
                margin-left: 57px;
                text-align: left;
                font-size: 14px;
                font-weight: bold;
                color: #5C5C6F;
                .use-details {
                    margin-bottom: 10px;
                }
            }
            .use-now {
                width: 284px;
                height: 40px;
                line-height: 40px;
                margin: 9% 16px 14px 20px;
                background: #FE3266;
                border-radius: 6px;
                cursor: pointer;
            }
            .use-now span{
                width: 64px;
                height: 16px;
                font-size: 16px;
                font-weight: 400;
                color: #FFFFFF;
            }

        }
    }
    ::v-deep .couponDialog {
        .el-dialog {
            height: 440px;
            margin: 38% 0 0 46%;
            box-shadow: 0 1px 3px rgba(0,0,0, 0);
            background: url('../../../assets/image/othersLive/coupon-bag.png');
            background-size: 100% 100%;
        }
        .el-icon-close:before {
            content: ''
        }
    }
</style>